#google-reviews {
    position: relative;
    .card-wrapper {
        margin-bottom: 30px;
        .card-up {
            height: 90px;
        }
    }
    .card-body {
        .stars{
            color: #ffb800;
        }
        .rotate-btn {
            padding-top: 10px!important;
            color: #33b5e5;
            display:block;
        }
    }
    .front {
        .content-wrap {
            height: 185px;
            clear: both;
            overflow: hidden;
        }
        .initial-name-wrap {
            background: #fb3;
            width: 120px;
            height: 120px;
            position: relative;
            .name-circle {
                font-size: 36px;
                font-weight: bold;
                color: #fff;
                position: absolute;
                top: 40%;
                left: 32%;
            }
        }
    }
    .back {
        .content-wrap {
            height: 255px;
            clear: both;
            overflow: hidden;
        }
        .rotate-btn {
            padding-top: 10px!important;
            color: #33b5e5;
            display:block;
            i {
                margin-top: 0;
            }
        }
        .stars{
            color: #ffb800;
        }
    }
}

@media screen and (max-width: 1920px){
    #google-reviews {
        .card-wrapper {
            // min-height: 530px;
            min-height: 570px;
        }
        .front {
            .content-wrap {
                // height: 200px;
                height: 238px;
            }
        }
        .back {
            .bottom-content-wrap {
                height: 170px;
            }
        }
    }
    
}

@media screen and (max-width: 1600px){
    #google-reviews {
        .back {
            .bottom-content-wrap {
                height: 185px;
            }
        }
    }    
}

@media screen and (max-width: 768px){
    #google-reviews {
        .front {
            .content-wrap {
                height: 185px;
            }
        }
    }
  }