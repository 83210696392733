/** Contact Us Styling Goes Here **/
#contact-us {
    .form-row {
        &.estimated-start-date {
          margin-top: 1.5rem!important;
          margin-bottom: -1.5rem!important;

          .error-message {
            left: 0!important;
          }
        }
        &.phonenum {
            .error-message {
                left: 0!important;
            }
        }
        .error-message {
            left: 40px;
        }
    }
    .btn-blue {
        background-color: $rs-bg-map-btn;
    }
    .mdb-auto-completer {
        position: absolute!important;
    }
     .md-form .prefix {
         left: 0;
     }
     .btn-group {
         text-align: left;
        //  margin-bottom: 20px;
         display: block;
         .prefix {
             font-size: 1.75rem;
             vertical-align: middle;
             margin-right: 8px;
         }
         .btn {
             padding: 6px 20px;
         }
     }
     .phone-wrap {
         font-size: 28px;
         margin-right: 6px;
     }
}
