html {
    font-size: 100%;
    @media screen and (max-width: 1920px){
        font-size: 110%;
    }
    @media screen and (max-width: 1600px){
        font-size: 100%;
    }
    @media screen and (max-width: 1199px){
        font-size: 95%;
    }
    @media screen and (max-width: 991px){
        font-size: 90%;
    }
    @media screen and (max-width: 767px){
        font-size: 85%;
    }
    @media screen and (max-width: 600px){
        font-size: 80%;
    }    
}
body {
    color: #404142;
    line-height: 1.6em;
    font-weight: 400;
    background: $rs-bg-main;
    font-family: $rs-f-family;
}

//fonts
.rs-f1    { font: $rs-f1-size; } /** 24px **/
.rs-f2    { font: $rs-f2-size; } /** 20px **/
.rs-f3    { font: $rs-f3-size; } /** 18px **/
.rs-f4    { font: $rs-f4-size; } /** 16px **/
.rs-f5    { font: $rs-f5-size; } /** 14px **/
.rs-f6    { font: $rs-f6-size; } /** 12px **/

.rs-f-small   { font-size:$rs-f-small-size;} /** 16px **/
.rs-f-medium  { font-size:$rs-f-medium-size;} /** 24px **/
.rs-f-large   { font-size:$rs-f-large-size;} /** 32px **/

.rs-f-small-rem   { font-size:$rs-f-small-size-rem;} /** 16px **/
.rs-f-medium-rem  { font-size:$rs-f-medium-size-rem;} /** 24px **/
.rs-f-large-rem   { font-size:$rs-f-large-size-rem;} /** 32px **/

.rs-bold-500 { font-weight: 500;  }
.rs-bold-600 { font-weight: 600;  }
.rs-bold     { font-weight: bold; }

.rs-mt-30 { margin-top: 30px; }
.rs-mt-35 { margin-top: 35px; }
.rs-mt-40 { margin-top: 40px; }

.rs-mb-30 { margin-bottom: 30px; }
.rs-mb-35 { margin-bottom: 35px; }
.rs-mb-40 { margin-bottom: 40px; }

.rs-pt-30 { padding-top: 30px; }
.rs-pt-35 { padding-top: 35px; }
.rs-pt-40 { padding-top: 40px; }

.rs-pb-30 { padding-bottom: 30px; }
.rs-pb-35 { padding-bottom: 35px; }
.rs-pb-40 { padding-bottom: 40px; }

//colors
.rs-c-white     { color: $rs-c-white; }
.rs-c-gray      { color: $rs-c-gray;  }
.rs-c-primary   { color: $rs-c-primary; }

//background Color 
.rs-bg-main       { background-color: $rs-bg-main;}

//Global Buttons 
.rs-btn-info     { background-color: $rs-bg-info-btn; color: $rs-c-white !important; }
.rs-btn-blue     { background-color: $rs-bg-blue-btn; color: $rs-c-white !important; }
.rs-btn-pink     { background-color: $rs-bg-pink-btn; color: $rs-c-white !important; }
.rs-btn-orange   { background-color: $rs-bg-orange-btn; color: $rs-c-white !important; }
.rs-btn-red      { background-color: $rs-bg-red-btn; color: $rs-c-white !important; }
.rs-btn-dark-red { background-color: $rs-bg-dark-red-btn; color: $rs-c-white !important; }