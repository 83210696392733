/** Nursery Software Page Styling Goes Here **/


@-webkit-keyframes tm-animation-floating {from{-webkit-transform:translate(0,0px)}65%{-webkit-transform:translate(0,15px)}to{-webkit-transform:translate(0,0px)}}

@-moz-keyframes tm-animation-floating {from{-moz-transform:translate(0,0px)}65%{-moz-transform:translate(0,15px)}to{-moz-transform:translate(0,0px)}}

.tm-animation-slide-horizontal {-webkit-animation:tm-animation-slide-horizontal 3s linear infinite alternate-reverse both;animation:tm-animation-slide-horizontal 3s linear infinite alternate-reverse both}

#nursery-software {
    padding: 30px 0;
    color: #273167;
    //font-family: "Roboto",sans-serif;
    
    .section-heading {
        color: #273167;
        //font-family: "Roboto",sans-serif;
        font-size: 1.875rem;
    }
    .image-bar-wrap {
        position: relative;
        overflow: hidden;
        cursor: default;
        height: auto;
        img {
            position: relative;
            display: block;
        }
        .text-wrapper {
            .line-1 {
                display: flex;
                justify-content: center;
                // align-items: center;
                position: absolute;
                top: 25%;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                h3 {
                    font-size: 2.5rem;
                    //font-family: "Roboto",sans-serif;
                    color: #ffffff;
                    font-weight: 700;
                    max-width: 850px;
                    margin: 0 auto;
                    text-align: center;
                    span {
                        font-weight: 400;
                    }
                }
            }
            .line-2 {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: -5%;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                p {
                    font-size: 1.5rem;
                    //font-family: "Roboto",sans-serif;
                    color: #ffffff;
                    font-weight: normal;
                    max-width: 500px;
                    margin: 0 auto;
                    text-align: center;
                }
            }
            .line-3 {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 40%;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
            }
        }
        .layer-mask-bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            width: 100%;
            height: 100%;
            background: linear-gradient(45deg, #064a81, #20649a);
            opacity: 0.5;
        }
    }
    .about-nursery-app {
        padding: 0;
        // background-color: #fff8f8;
    }
    // .curve-wrapper {
    //     position: relative;
    //     left: -5px;
    //     box-sizing: border-box;
    //     width: 100%;
    //     padding: 150px 75px 70px 75px;
    //     transition: opacity .5s ease;
    //     overflow: hidden;
    //     .curve-inner {
    //         background-size: contain;
    //         background-repeat: repeat-x;
    //         background-position: center top;
    //         background-color: rgba(0, 0, 0, 0);
    //         background-image: URL('../../../assets/img/icons/curve-bg.png');
    //         background-attachment: scroll;
    //         position: absolute;
    //         top: 0;
    //         bottom: 0;
    //         right: 0;
    //         left: 0;
    //         width: 100%;
    //         height: 100%;
    //         z-index: 0;
    //         overflow: hidden;
    //     }
    // }
    .feature {
        position: relative;
        width: 100%;
        padding: 50px 15px 0 15px;
        .feature-inner {
            .featured-img {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                text-align: left;
                margin-bottom: 35px;
                img {
                    height: auto;
                    width: 100%;
                    // max-width: 100%;
                    vertical-align: top;
                    position: relative;
                    z-index: 10;
                    &.mobile-img {
                        width: 370px;
                        height: auto;
                        position: relative;
                        left: 80px;
                        top: 0;
                    }
                }
                &.img-block {
                    left: -50px;
                    margin-top: -50px;
                    position: relative;
                    &:before {
                        left: 40px;
                        top: 50px;
                        border: 43px solid #fafafa;
                        border-radius: 50%;
                        content: '';
                        height: 318px;
                        position: absolute;
                        width: 318px;
                    }
                    &:after {
                        bottom: 50px;
                        right: -10px;
                        border: 43px solid #fafafa;
                        border-radius: 50%;
                        content: '';
                        height: 318px;
                        position: absolute;
                        width: 318px;
                    }
                }
                &.tm-animation-floating{
                    animation-name: tm-animation-floating;
                    animation-duration:3s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in-out;
                    -webkit-animation-name: tm-animation-floating;
                    -webkit-animation-duration:3s;
                    -webkit-animation-iteration-count:infinite;
                    -webkit-animation-timing-function:ease-in-out;
                    -moz-animation-name: Floating;
                    -moz-animation-duration:3s;
                    -moz-animation-iteration-count:infinite;
                    -moz-animation-timing-function:ease-in-out
                }
            }
        }
        .icon-wrapper {
            margin-bottom: 35px;
            img {
                margin-top: 40px;
                margin-bottom: 0;
            }
        }
        .text-wrapper {
            h2 {
                font-size: 2.5rem;
                text-align: left;
                margin-top: 12px;
                margin-bottom: 40px;
                color: #273167;
                //font-family: "Roboto",sans-serif;
                font-weight: 400;
            }
            .line-1 {
                font-size: 1.125rem;
                color: #4F4F4F;
                line-height: 40px;
                margin-bottom: 35px;
            } 
            .ordered-list {
                position: relative;
                margin-bottom: 50px;
                font-size: 1rem;
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    color: #777b92;
                    li {
                        list-style-type: none;
                        padding-left: 25px;
                        position: relative;
                        margin-bottom: 12px;
                        i {
                            // font-size: 80%;
                            font-size: 120%;
                            // line-height: 2;
                            line-height: 1.25;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 0;
                            color: #e94fca;
                        }
                    }
                }
            } 
            .accordion {
                .card {
                    .card-header {
                        background-color: #459dff;
                        a {
                            font-size: 1rem;
                            color: #fff;
                        }
                    }
                    .card-body {
                        p {
                            font-size: 1.125rem;
                            color: #4F4F4F;
                        }
                        ul {
                            font-size: 1rem;
                            color: #4F4F4F;
                            li {
                                margin-bottom: 5px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }   
        }
    }
    .read-more {
        color: #da2128;
        cursor: pointer;
        vertical-align: middle;
    }
    #about-app-read-more {
        display: none;
    }
}