@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@import "../../../../node_modules/compass-mixins/lib/compass/css3";

/// Mixin to place items on a circle

/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width:  $circle-size;
  height: $circle-size;
  border-radius: 50%;
  padding: 0;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top:  50%;
    left: 50%;
    z-index: 1;
    margin: -($item-size / 2);
    width:  $item-size;
    height: $item-size;

    $angle: (360 / $item-count);
    $rot: 270;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

#learning-lifecycle {
  padding: 30px 15px;
  .circle-container {
    @include on-circle($item-count: 7, $circle-size: 38em, $item-size: 8em);
    margin: 0 auto;
    border: solid 5px #fb3;
    background: #48789f;
    // -webkit-animation:spin 15s linear;
    // -moz-animation:spin 15s linear;
    // animation:spin 15s linear;

    img {
      display: block;
      max-width: 100%;
      border-radius: 50%;
      filter: opacity(90%);
      border: solid 4px #666;
      transition: .15s;

      &:hover {
        filter: opacity(100%);
        border: none;
      }
    }
  }
  .lifecycle {
    position: relative;
    top: 50%;
    display: flex;
    justify-content: center;
    z-index: 1;
    color: #fff;
    h3 {
        font-size: 2rem;
        font-weight: bold;
    }
    .arrow-img {
      background: URL('../../../assets/img/icons/arrow-1.png');
      background-repeat: no-repeat;
      position: absolute;
      top:-190px;
      width: 430px;
      height: 430px;
      -webkit-animation:spin 15s linear infinite;
      -moz-animation:spin 15s linear infinite;
      animation:spin 15s linear infinite;
    }
  }
}
/** Learning Lifecycle Modal Popup **/
.modal {
  .modal-header {
    .heading {
      font-family: avenir-light,sans-serif;
      font-weight: bold!important;
    }
    &.step1 {
      background-color: #02aab5!important;
    }
    &.step2 {
      background-color: #fbcd05!important;
    }
    &.step3 {
      background-color: #812c25!important;
    }
    &.step4 {
      background-color: #68b763!important;
    }
    &.step5 {
      background-color: #c81f9a!important;
    }
    &.step6 {
      background-color: #3285ad!important;
    }
    &.step7 {
      background-color: #ffa602!important;
    }
  }
  .modal-body {
    overflow-y: auto;
    max-height: 480px;
    p {
      font-family: avenir-light,sans-serif;
    }
    .para-list {
      font-family: avenir-light,sans-serif;
      font-weight: normal;
      padding: 0 20px;
      margin: 0;
    }
    .read-more {
      cursor: pointer;
      color: #da2128;
      vertical-align: middle;
    }
    #readmore-text-one {
      display: none;
    }
    #readmore-text-two {
      display: none;
    }
    #readmore-text-three {
      display: none;
    }
    #readmore-text-four {
      display: none;
    }
    #readmore-text-five {
      display: none;
    }
    #readmore-text-six {
      display: none;
    }
    #readmore-text-seven {
      display: none;
    }
    #readmore-text-eight {
      display: none;
    }
    #readmore-text-nine {
      display: none;
    }
    #readmore-text-ten {
      display: none;
    }
    #readmore-text-eleven {
      display: none;
    }
    #readmore-text-twelve {
      display: none;
    }
  }
  .modal-footer {
    padding: 8px;
    clear: both;
    .mdb-lightbox  {
      figure {
        width: calc(100%/2);
        float: left;
        margin: auto;
        .ng-thumb {
          border: 2px solid #fb3;
          padding: 3px;
        }
      }
    }
    .ng-overlay {
      width: 900px;
      height: 650px;
      left: -40%;
      top: 0;
      .ng-gallery-content {
        width: 900px;
        height: 600px;
        left: -40%;
        top: 40px;
        .nav-left {
          left: -194px;
          top: 60%;
        }
        .nav-right {
          right: -194px;
          top: 60%;
        }
      }
    }
  }
}

@media screen and (max-width: 575px){
  #learning-lifecycle {
    .circle-container {
      @include on-circle($item-count: 7, $circle-size: 32em, $item-size: 6em);
      img {
        filter: opacity(100%);
      }
    }
  }
}

@media screen and (max-width: 420px){
  #learning-lifecycle {
    .circle-container {
      @include on-circle($item-count: 7, $circle-size: 20em, $item-size: 5em);
    }
  }
}
