/** Careers Page Stylsheet Goes Here **/
#provision-planning {
    position: relative;
    .section-wrapper {
      padding: 25px 20px;
    }
    .stepper {
      padding-bottom:12px;
      margin-bottom: 0;
      .active {
        .step-title {
          background: #f0f0f0;
        }
      }
    }
  }