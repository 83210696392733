/** Preschool Room Stylsheet Goes Here **/
#preschool {
  position: relative;
  padding: 40px 0;
  .tab-container {
    .tab-content  {
        box-shadow: none;
        padding-top: 0;
    }
  }
  .cardimg{
    width: 100%;
    height: 426px;
    object-fit: cover;
  }
  .accordion {
    .card {
      margin-bottom: 1.2rem;
      .card-body {
        padding: 0 1rem;
      }
    }
  }
  .md-pills {
    li {
      padding: .5rem;
      .nav-link {
        padding: .5rem 0.70rem;
      }
    }
  }
  .md-form {
    .prefix {
      left:0;
    }
  }
  .mdb-auto-completer {
    position: absolute!important;
    z-index: 99999!important;
    width: 50%;
    left: 5%;
    top: 5%;
  }
  .md-tabs {
    background-color: $rs-bg-tabs-secondary;
  }
  .testimonial-card {
    .card-up {
      background-color: $rs-bg-tabs-secondary;
    }
  }
  ul.stepper {
    margin: 0em -1.5rem;
    padding: 0 1.5rem;
    .step-title {
      span {
        display: block;
        text-align: left;
      }
    }
    .step {
      &:before{
        left: 0;
      }
    }
  }
}