/** Main Section Styling Goes Here **/
section {
    position: relative;
    padding: 3.75rem 0;
}
.section-heading {
    color: $rs-c-main-heading;
    font-size: $rs-f-main-heading;
}
.secondary-heading {
    color: $rs-c-secondary-heading!important;
}
.section-text {
    color: $rs-c-main-text!important;
    font-size: $rs-f-main-text;
}
.breadcrumb {
    background: #fff;
    padding: 50px 30px 15px 30px;
    clear: both;
    margin-bottom: 0;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
}
.breadcrumb-item+.breadcrumb-item::before {
    content: "/";
    align-items: center;
    font-size: 18px;
    padding: 0 12px;
}
.border-line {
    margin-left: 15px;
    margin-right: 15px;
    border: 1px solid $rs-c-warning;
}
.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
}
.container-fluid{
    position: static;
    padding: 0px 5%;
    margin: 0 auto;
}
.section-wrapper {
    margin: 0 15px;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.18);
    border-radius: 8px;
    padding: 3.75rem 1.25rem;
}
.badge {
    font-size: 85%;
    line-height: 1.5;
    padding: 0.45em 0.75em;
    span {
        text-transform: capitalize;
    }
}
.form-control {
    color: #757575;
    //font-weight: 300;
}
.md-form input:not([type]), .md-form input[type="text"]:not(.browser-default), .md-form input[type="password"]:not(.browser-default), .md-form input[type="email"]:not(.browser-default), .md-form input[type="url"]:not(.browser-default), .md-form input[type="time"]:not(.browser-default), .md-form input[type="date"]:not(.browser-default), .md-form input[type="datetime"]:not(.browser-default), .md-form input[type="datetime-local"]:not(.browser-default), .md-form input[type="tel"]:not(.browser-default), .md-form input[type="number"]:not(.browser-default), .md-form input[type="search"]:not(.browser-default), .md-form input[type="search-md"], .md-form textarea.md-textarea{
    border-bottom:1px solid #ced4da!important;
    box-shadow: none!important;
    font-weight: 300!important;
    position: relative;
}

.md-form input:not([type]):focus:not([readonly]), .md-form input[type="text"]:not(.browser-default):focus:not([readonly]), .md-form input[type="password"]:not(.browser-default):focus:not([readonly]), .md-form input[type="email"]:not(.browser-default):focus:not([readonly]), .md-form input[type="url"]:not(.browser-default):focus:not([readonly]), .md-form input[type="time"]:not(.browser-default):focus:not([readonly]), .md-form input[type="date"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), .md-form input[type="number"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search-md"]:focus:not([readonly]), .md-form textarea.md-textarea:focus:not([readonly]) {
    border-bottom:1px solid #4285f4!important;
    box-shadow: 0 1px 0 0 #4285f4!important;
}

.md-form mdb-select {
    border-bottom: 0px solid #ced4da!important;
    box-shadow: none!important;

    .placeholder {
        font-weight:400;
        color: #757575!important;
    }
    &:focus {
        border-bottom:1px solid #4285f4!important;
        box-shadow: 0 1px 0 0 #4285f4!important;
    }
}
.mdb-auto-completer {
   position: absolute;
   top: 0;
   left: 40px;
}
.file-path {
    font-weight: 300;
}

.testimonial-card {
    .card-up {
        height: 90px!important;
    }
}

/** Stepper Styling **/
  ul.stepper.horizontal .step .step-title::before {
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
  }

//   ul.stepper.horizontal .step:nth-of-type(1) .step-title {
//     &:before {
//       content: "\f007";
//     }
//   }

//   ul.stepper.horizontal .step:nth-of-type(2) .step-title {
//     &:before {
//       content: "\f004";
//     }
//   }

//   ul.stepper.horizontal .step:nth-of-type(3) .step-title {
//     &:before {
//       content: "\f005";
//     }
//   }

/** Return to Top **/
#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background:#fb3;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    i {
        color: #fff;
        margin: 0;
        position: relative;
        left: 16px;
        top: 13px;
        font-size: 19px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    &:hover {
        background: #ffb114;
    }
}

.side-menu-links {
    position: fixed;
    // top: 28%;
    top: 35%;
    right: 0;
    // z-index: 1;
    z-index: 2;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 16px;
        li {
            background: #33b5e5;
            // padding: 10px 5px;
            padding: 8px;
            margin-bottom: 5px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            a {
                color: #fff;
                padding: 3px 5px 0 5px;
                text-align: center;
                display: block;
                &.side-link-call {
                    i {
                        transform: rotate(150deg);
                    }
                    &:after {
                        //content: "C Us";
                        margin-left: 6px;
                    }
                }
                &.side-link-email {
                    &:after {
                        //content: "C Us";
                        margin-left: 6px;
                    }
                }
                &.side-link-eya {
                    &:after {
                        content: "";
                        margin-left: 6px;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
                background: #fb3;
                &:hover {
                    background: #f6a80b;
                }
            }
            &:hover {
                background: #25a7d7;
            }
        }
    }
}

.side-member-links {
    position: fixed;
    // top: 14%;
    top: 25%;
    left: 0;
    z-index: 2;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 16px;
        li {
            background: #f3f3f3;
            padding: 5px;
            margin-bottom: 5px;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            a {
                color: #fff;
                text-align: center;
                display: block;
                width: 60px;
                height: 60px;
                img {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}

.social-share {
    h4 {
        display: inline-block;
        margin-right: 10px;
        vertical-align: top;
        color: #414042;
        font-size: 22px;
    }
    i {
        vertical-align: middle;
    }
}

#baby-outdoor-acitivity {
    position: relative;  
    .lightbox-caption {
        font-size: 1rem;
        max-height: 120px;
        background: #fff;
        color: #404142!important;
        padding: 10px;
        position: relative;
        top: -4px;
    } 
}


// .close-popup {
//     background: url("assets/img/default-skin.png");
// }
// .fullscreen-toogle {
//     background: url("assets/img/default-skin.png");
// }
// .zoom-toogle {
//     background: url("assets/img/default-skin.png");
// }
// .nav-left {
//     span {
//         &::before {
//             background: url("assets/img/default-skin.png");
//         }
//     }
// }
// .nav-right {
//     span {
//         &::before {
//             background: url("assets/img/default-skin.png");
//         }
//     }
// }