/** Nursery Policy Styling Goes Here **/
#nursery-policies {
    position: relative;
    padding: 40px 0;
    .tab-container {
      .tab-content  {
          box-shadow: none;
          padding-top: 0;
      }
    }
    .cardimg{
      width: 100%;
      height: 426px;
      object-fit: cover;
    }
    .accordion {
      .card {
        margin-bottom: 1.2rem;
        .card-header {
          padding: 2rem 1.5rem;
        }
        .card-body {
          padding: 0 1rem;
          border: 1px solid #ccc;
        }
        .mdb-accordion-indicator {
          top: 39px;
        }
      }
      .policy-list {
        padding: 22px 10px;
        max-height: 730px;
        overflow-y: auto;
        .policy-box {
          line-height: 24px;
          box-shadow: 1px 2px 6px #ddd;
          margin: 10px 0;
          padding: 20px;
          font-size: 1rem;
          a {
            padding: 10px 0;
            color: #9a1818;
            font-family: avenir-light,sans-serif;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .md-pills {
      li {
        padding: .5rem;
        .nav-link {
          padding: .5rem 0.70rem;
        }
      }
    }
    .md-form {
      .prefix {
        left:0;
      }
    }
    .mdb-auto-completer {
      position: absolute!important;
      z-index: 99999!important;
      width: 50%;
      left: 5%;
      top: 5%;
    }
    ul.stepper {
      margin: 0em -1.5rem;
      padding: 0 1.5rem;
      .step-title {
        span {
          display: block;
          text-align: left;
        }
      }
      .step {
        &:before{
          left: 0;
        }
      }
    }
  }

  