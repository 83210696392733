#eyfs {
    .eyfs-left-section {
        background: #00bcd4;
        padding:1.5625rem 0.9375rem;
        .accordion {
            .box-title {
                display: inline-block;
                padding: 1.125rem;
                font-size: 14px;
                font-weight: 400;
                background: hsl(358, 74%, 49%);
                margin-right: 5px;
                color: #fff;
                width:50px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &.cal {
                    background: #0090f6;
                }
                &.pd {
                    background: #ffcc1c;
                }
                &.psed {
                    background: #fc0058;
                }
                &.lit {
                    background: #ada8b1;
                }
                &.mat {
                    background: #9c5ad2;
                }
                &.uw {
                    background: #00dd49;
                }
                &.ead {
                    background: #6805a5;
                }
            }
            .card-header {
                border-bottom: 1px solid #afd4ee;
                padding: 0;
                h5 {
                    font-size: 1.125rem;
                }
                a {
                    color: #150721;
                }
                &:first-child {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }
            }
            .card-body {
                padding: 0;
            }
            .list-group-item {
                &.active {
                    font-weight: 400;
                }
            }
        }
    }
    .eyfs-right-section {
        padding: 25px 0;
        .md-tabs {
            border-radius: .25rem;
            .nav-item {
                height: 40px;
            }
        }
        .form-check-label  {
            padding-left: 25px!important;
        }
    }
}