/** Media Query For XXLarge Screen Size **/
@media screen and (max-width: 1920px){
    header {
        .carousel-item {
            .view1 {
                height: 525px;
            }
        }
        .carousel-caption {
            .top-box {
                top: 75px;
                span {
                    font-size: 1.125rem;
                }
            }
            .funding-box {
                top: 110px;
            }
            &.full-width {
                max-width: 100%!important;
            }
        }
    }
}

/** Media Query For XLarge Screen Size **/
@media screen and (max-width: 1600px){

}

/** Media Query For Large Screen Size **/
@media screen and (max-width: 1199px){
    header {
        .carousel-item {
            .view {
                height: auto!important;
            }
            .view1 {
                height: auto;
            }
            .carousel-caption {
                .top-box {
                    top: 122px;
                    span {
                        font-size: 1rem;
                    }
                }
                .funding-box {
                    top: 125px;
                    .box-text {
                        font-size: 1.3rem!important;
                    }
                    .card-title {
                        font-size: 1.20rem!important;
                    }
                }
                
            }
        }
    }
}

/** Media Query For Medium Screen Size **/
@media screen and (max-width: 991px){
    header {
        .navbar-nav {
            padding: 20px 0;
        }
        .carousel-item {
            .view1 {
                height: 375px;
            }
            .carousel-caption {
                bottom: 10%!important;
                .top-box {
                    top: 40px!important;
                    span {
                        font-size: 1rem!important;
                    }
                }
                .funding-box {
                    top: 45px!important;
                    .card-title {
                        font-size: 1.125rem!important;
                    }
                    .card-image {
                        .yellow-txt {
                            margin-bottom: 8px!important;
                        }
                        .blue-txt {
                            margin-bottom: 8px!important;
                        }
                        .text-white {
                            padding: 0.5rem!important;
                            .box-text {
                                font-size: 1.125rem!important;
                            }
                        }
                    }
                }  
            }
        }
    }
    #babyroom {
        .cardimg {
            height: auto!important;
        }
    }
    #toddler {
        .cardimg {
            height: auto!important;
        }
    }
    #preschool {
        .cardimg {
          height: auto!important;
        }
    }
    #childcare-funding {
        .childcare-feature {
            .feature-inner {
                .featured-img {
                    &.img-block {
                        left: 0!important;
                    }
                }
                .mr-img-left {
                    margin-left: 0!important;
                }
            }
        }
        .section-heading {
            font-size: 1.5rem!important;
        }
    }
    #learning-lifecycle {
        .lifecycle {
            .arrow-img {
                background: URL('../../../assets/img/icons/arrow-2.png')!important;
                width: 350px!important;
                height: 350px!important;
                top:-155px!important;
            }
        }
    }
    #nursery-software {
        .image-bar-wrap {
            .line-1 {
                top: 20%!important;
            }
            .line-2 {
                top: 10%!important;
            }
            .line-3 {
                top: 60%!important;
            }
        }
        .curve-wrapper {
            left: 15px!important;
            padding: 50px 24px 100px 24px!important;
        }
        .feature {
            .featured-img {
                &.img-block {
                    left: 0!important;
                }
            }
        }
    }
    
    .side-menu-links {
        .side-link-call {
            &:after {
                content: ''!important;
                margin-left: 0!important;
            }
        }
        .side-link-email {
            &:after {
                content: ''!important;
                margin-left: 0!important;
            }
        }
    }
    
    .modal {
        .modal-footer {
            .ng-overlay {
                width: 730px!important;
                height: 560px!important;
                left: -120px!important;
                top: 0!important;
                .ng-gallery-content {
                    width: 730px!important;
                    height: 485px!important;
                    left: -120px!important;
                    top: 40px!important;
                }
            }
        }
    }
    #nanny-service {
        .section-wrapper {
            .tab-container {
                .md-tabs {
                    .nav-link {
                        padding: 8px 5px;
                    }
                }
            }
            .cardimg{
                height: auto!important;
            }
        }
    }
    #nanny-registration {
        .stepper {
            .step-new-content {
                .nanny-personal-info{
                    .form-header {
                        h3 {
                            font-size: 16px;
                            margin-bottom: 0;
                        }
                        margin-bottom: 2rem;
                    }
                    .md-form {
                        .prefix {
                            font-size: 1.5rem;
                            top: 6px;
                        }
                    }
                    .btn-group {
                        .prefix {
                            font-size: 1.5rem;
                            top: 6px;
                        }
                        .btn {
                            padding: 8px 12px!important;
                        }
                    }
                    top: 45px!important;
                    margin-bottom: 70px!important;
                    padding: 15px!important;
                }
            }
        }
    }
}

/** Media Query For Small Screen Size **/
@media screen and (max-width: 767px){
    header {
        margin-top: 80px;
        #carousel-example-2 {
            .carousel-item {
              .carousel-caption {
                .funding-box {
                  .card-image {
                    .blue-txt {
                      margin-bottom: 8px;
                    }
                    .yellow-txt {
                      margin-bottom: 8px;
                    }
                  }
                  .box-text {
                    font-size: 1rem;
                  }
                  .card-title {
                    font-size: 16px;
                  }
                }
                .top-box {
                  span {
                    line-height: 18px;
                  }
                }
                p {
                  line-height: 18px;
                }
              }
            }
        }
        .navbar-brand {
            img {
                height: 48px;
            }
        }
    }
    .container{
        max-width: 94%;
    }
    .tab-container {
        .tab-content  {
            padding-top: 40px!important;
        }
    }
    #childcare-funding {
        .childcare-feature {
            .feature-inner {
                .featured-img {
                    &.img-block {
                        left: 0!important;
                    }
                }
            }
        }
        .section-heading {
            font-size: 1.5rem!important;
        }
    }
    #nursery-policies {
        .mdb-accordion-indicator {
            top: 20px!important;
        }
    }
    .modal {
        .modal-footer {
            .ng-overlay {
                width: 650px!important;
                height: 480px!important;
                left: -75px!important;
                top: 0!important;
                .ng-gallery-content {
                    width: 650px!important;
                    height: 430px!important;
                    left: -75px!important;
                    top: 40px!important;
                }
            }
        }
    }
    #fee {
        .card-body {
            .card-title {
                h5 {
                    font-size: 13px;
                }
            }
        }
    }
}

/** Media Query For XSmall Screen Size **/
@media screen and (max-width: 575px){
    header {
        margin-top: 68px;
        .carousel-item {
            .view1 {
                height: 320px;
            }
            .carousel-caption {
                bottom: 10%!important;
                .top-box {
                    top: 30px!important;
                    span {
                        font-size: 1rem!important;
                    }
                }
                .para-list {
                    line-height: 18px;
                }
                .funding-box {
                    top: 35px!important;
                    .card-image {
                        .text-white {
                            .btn {
                                display: inline-block;
                                padding: .75rem 1.15rem;
                            }
                            .box-text {
                                font-size: 12px!important;
                                margin-bottom: 8px!important;
                            }
                            .card-title {
                                font-size: 12px!important
                            }
                            p {
                                font-size: 0.75rem!important;
                                margin-bottom:8px!important;
                            }
                        }
                    }
                }
                .btn {
                    display: none;
                }
            }
        }
    }
    .section-wrapper {
        padding: 1.25rem;
        margin: 0;
    }
    .container-fluid {
        padding:0;
    }
    .side-menu-links {
        .side-link-call {
            &:after {
                content: ''!important;
                margin-left: 0!important;
            }
        }
        .side-link-email {
            &:after {
                content: ''!important;
                margin-left: 0!important;
            }
        }
    }
    #about-us {
        .md-tabs {
            margin: 0!important;
        }
    }
    #learning-lifecycle {
        .lifecycle {
            h3 {
              font-size: 1.8rem!important;
            }
            .arrow-img {
              background: URL('../../../assets/img/icons/arrow-3.png')!important;
              width: 300px!important;
              height: 300px!important;
              top:-132px!important;
            }
        }
    }
    #nursery-policies {
        .mdb-accordion-indicator {
            top: 16px!important;
        }
    }
    #nursery-software {
        .image-bar-wrap {
            .line-1 {
                top: 15%!important;
                h3 {
                    font-size: 2.2rem!important;
                }
            }
            .line-2 {
                top: 0!important;
                p {
                    font-size: 1.25rem!important;
                }
            }
        }
        .curve-wrapper {
            left: 15px!important;
            padding: 20px 24px 100px 24px!important;
        }
    }
    #contact-us {
        .form-row {
            .error-message {
                left: 32px!important;
            }
        }
    }
    .modal {
        .modal-footer {
            .ng-overlay {
                width: 565px!important;
                height: 100%!important;
                left: 0!important;
                top: 0!important;
                .ng-gallery-content {
                    width: 565px!important;
                    height: 100%!important;
                    left: 0!important;
                    top: 0!important;
                }
            }
        }
    }
}

/** Media Query For XXSmall Screen Size **/
@media screen and (max-width: 420px){
    header {
        .carousel-indicators {
            top: 80%!important;
        }
        .carousel-item {
            .view1 {
                height: 225px;
            }
            .carousel-caption {
                max-width: 300px!important;
                bottom: -5%!important;
                p {
                    line-height: 12px!important;
                }
                .top-box {
                    top: 10px!important;
                    padding: 6px!important;
                    span {
                        line-height: 14px!important;
                        font-size: 8px!important;
                    }
                }
                .funding-box {
                    top: 10px!important;
                    .card-image {
                        .blue-txt {
                            font-size: 8px!important;
                            margin-bottom: 4px!important;
                        }
                        .yellow-txt {
                            font-size: 8px!important;
                            margin-bottom: 4px!important;
                        }
                        .text-white {
                            .btn {
                                display: inline-block;
                                padding: 5px 10px;
                                font-size: 8px;
                                i {
                                    font-size: 8px!important;
                                    margin-right: 3px!important;
                                }
                            }
                            .box-text {
                                font-size: 8px!important;
                                margin-bottom: 4px!important;
                            }
                            .card-title {
                                font-size: 8px!important;
                                margin-bottom: 4px!important;
                                padding-top: 4px!important;
                            }
                            p {
                                font-size: 0.6rem!important;
                                margin-bottom:6px!important;
                            }
                        }
                    }
                }
                h3 {
                    font-size: 1.2rem!important;
                    margin-bottom: 8px!important;
                }
                p {
                    font-size: 0.75rem!important;
                    margin-bottom: 10px!important;
                }
                .para-list {
                    padding: 0 5px!important;
                    font-size: 0.75rem;
                }
                &.full-width {
                    right: 5%!important;
                    left: 5%!important;
                }
            }
        } 
    }
    ul.stepper .step-new-content {
        margin-left: 20px!important;
        margin-right: 0!important;
    }
    .navbar-brand {
        margin-right: 5px;
    }
    .side-menu-links {
        top: 25%!important;
    }
    .home-section {
        .section-heading {
            font-size: 1.5rem;
        }
    }
    #childcare-funding {
        .childcare-feature {
            .feature-inner {
                .featured-img {
                    top: -30px!important;
                    &.img-block {
                        &:before {
                            width: 130px!important;
                            height: 130px!important;
                        }
                        &:after {
                            width: 130px!important;
                            height: 130px!important;
                        }
                    }
                }
            }
        }
        .section-heading {
            line-height: 24px!important;
            font-size: 11px!important;
        }
    }
    #careers {
        .sbmbtn {
            width: 100px!important;
        }
        .rstbtn {
            width: 100px!important;
        }
    }
    #learning-lifecycle {
        .lifecycle {
            right: 2%!important;
            h3 {
                font-size: 1.4rem!important;
                margin-left: 10px;
            }
            .arrow-img {
                background: URL('../../../assets/img/icons/arrow-4.png')!important;
                width: 215px!important;
                height: 215px!important;
                top:-94px!important;
                left: 60px;
            }
        }
    }
    #nursery-software {
        .image-bar-wrap {
            .line-1 {
                top: 8%!important;
                h3 {
                    font-size: 1.6rem!important;
                    max-width: 280px!important;
                }
            }
            .line-2 {
                top: -5%!important;
                p {
                    font-size: 0.8rem!important;
                    max-width: 250px!important;
                }
            }
            .line-3 {
                .btn {
                    padding: 8px 18px!important;
                }
            }
        }
        .feature {
            .text-wrapper {
                h2 {
                    font-size: 2rem!important;
                    margin-bottom: 20px!important;
                }
                .line-1 {
                    font-size: 1.25rem!important;
                    line-height: 28px!important;
                    margin-bottom: 25px!important;
                }
                .ordered-list {
                    font-size: 1.125rem!important;
                    margin-bottom: 30px!important;
                }
            }
            .feature-inner {
                .featured-img {
                    img {
                        &.mobile-img {
                            width: 100%!important;
                            height: auto!important;
                            left: 0!important;
                        }
                    }
                    &.img-block {
                        margin-top: -30px!important;
                        &:before {
                            left: 25px!important;
                            top: -10px!important;
                            width: 150px!important;
                            height: 150px!important;
                        }
                        &:after {
                            bottom: 40px!important;
                            right: 30px!important;
                            width: 150px!important;
                            height: 150px!important;
                        }
                    } 
                } 
            }
        }
    }
    .modal {
        .modal-footer {
            .ng-overlay {
                width: 350px!important;
                height: 100%!important;
                .ng-gallery-content {
                    width: 350px!important;
                    height: 100%!important;
                }
            }
        }
    }
    #fee {
        .card-body {
            .card-title {
                h5 {
                    font-size: 12px;
                }
            }
        }
    }
    #baby-outdoor-acitivity {
        .lightbox-caption {
            font-size: 11px;
            padding: 8px;
        } 
    }
    #nanny-registration {
        .stepper {
            .step-new-content {
                .nanny-personal-info{
                    .form-header {
                        h3 {
                            font-size: 14px;
                        }
                    }
                    .btn {
                        padding: 10px 18px;
                    }
                    .btn-group {
                        .prefix {
                            margin-right: 4px!important;
                        }
                        .btn {
                            padding: 8px!important;
                        }
                    }
                }
            }
        }
    }
}
