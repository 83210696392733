/************************** Fonts ****************************/

//Global Font Size
$rs-f-family: "Roboto", sans-serif;
$rs-f1-size: 24px;
$rs-f2-size: 20px;
$rs-f3-size: 18px;
$rs-f4-size: 16px;
$rs-f5-size: 14px;
$rs-f6-size: 12px;

$rs-f-small-size: 16px;
$rs-f-medium-size: 24px;
$rs-f-large-size: 32px;

$rs-f-small-size-rem: 1rem; /** 16px **/
$rs-f-medium-size-rem: 1.5rem; /** 24px **/
$rs-f-large-size-rem: 2rem; /** 32px **/
$rs-f-xlarge-size-rem: 2.25rem; /** 36px **/
$rs-f-xxlarge-size-rem: 2.5rem; /** 40px **/

// Navigation Links Font Size 
$rs-f-navlink: 1rem;

// Page Main Heading Font Size 
$rs-f-main-heading: 2.5rem;

// Page Main Text Font Size 
$rs-f-main-text: 1rem;


/************************** Global Color Scheme ****************************/

//Global Colors
$rs-c-white : #fff;
$rs-c-gray  : gray;
$rs-c-red   :  #e25b26;
$rs-c-orange:  #fcb03b;
$rs-c-primary : #005d84;
$rs-c-warning : #fb3;
$rs-c-blue: #2196f3;

// Header Background Color
$rs-bg-header: #3f729b;

//Main Body Background Color
$rs-bg-main: #ffffff;

//Page Main Heading Color
$rs-c-main-heading: #404142; 

//Page Main Heading Color
$rs-c-secondary-heading: #fcb03b; 

//Page Main Text Color
$rs-c-main-text: #4F4F4F;

// Footer Background Color
$rs-bg-footer: #3f729b;

// Footer Text Color
$rs-c-footer: #ffffff!important;

// Navigation Links Color 
$rs-c-navlink: #ffffff!important;

//Tabs BG 
$rs-bg-tabs-primary: #3f729b;
$rs-bg-tabs-secondary: #3f51b5;

// Slider Heading Color 
$rs-c-slider-heading: #ffffff;

// Slider Paragraph Color 
$rs-c-slider-text: #ffffff;

//Button Background Color

//Info Button
$rs-bg-info-btn: #3f729b;

//Blue Button
$rs-bg-blue-btn: #1976d2;

//Pink Button
$rs-bg-pink-btn: #e94fca;

//Red Button
$rs-bg-red-btn: #d32f2f;

//Dark Red Button
$rs-bg-dark-red-btn: #880e4f;

//Orange Button
$rs-bg-orange-btn: #ff7043;

//Contact Us Map
$rs-bg-map-btn : #1976d2!important;










