/** Fee Page Styling Goes Here **/
#fee {
    position: relative;
    padding: 40px 0;
    .card-body {
        &.active {
            background: #FFBB33!important;
            .card-title {
                color: #fff;
            }
            .rs-btn-info {
                background-color: #ec407a;
                box-shadow: none;
            }
        }
        .card-title {
            h5 {
                cursor: pointer;
                margin: 0 auto;
            }
        }
    }
    #toddlerRoomFee {
        display: none;
    }
    #preSchoolRoomFee {
        display: none;
    }
}