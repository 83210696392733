.md-form input[type=text] {
  box-shadow: 0 1px 0 0 #ffc515 !important;
  border-bottom: 1px solid #ffc515 !important;
}

.md-form input[type=text]:focus {
  box-shadow: 0 1px 0 0 #ffc515 !important;
  border-bottom: 1px solid #ffc515 !important;
}

mdb-select {
  box-shadow: 0 1px 0 0 #ffc515 !important;
  border-bottom: 1px solid #ffc515 !important;
}

