
/* Define standard variables and values for website */
$bgcolor: lightblue;
$textcolor: darkblue;
$fontsize: 18px;
$headerheight: 600px;
$myvar: true;

/* Use the variables */
 header {
    margin-top: 94px;
   .navbar {
      background-color: $rs-bg-header;
      .container {
        max-width: 100%;
      }
      .nav-link {
        font-size: $rs-f-navlink;
        color: $rs-c-navlink;
        &:hover {
          background-color: #4a7fa7;
        }
      }
      .special-color {
        background-color: #4a7fa7!important;
      }
   }
 }
  .header, #intro {
    height: $headerheight;
  }

  .header, #intro2 {
    height: 60px;
    background-image:none;
  }

.logo{
  width:160px !important;
  height:60px !important;
  margin-top: -15px;
  margin-bottom: -15px;
  border-radius: 10px;
}

/** Carousel Slider **/
// #video-carousel-example2 {
//   position: relative;
//   .carousel-item {
//     .view {
//       height: 550px;
//     }
//     .rgba-black-strong {
//       background: rgba(0,0,0,.6);
//     }
//   }
//   .carousel-caption {
//     position: absolute;
//     right:0;
//     bottom: 30%;
//     text-align: left;
//     max-width: 680px;
//     h3 {
//       font-size: $rs-f-xxlarge-size-rem;
//       text-align: left;
//       margin-bottom: 20px;
//       color: $rs-c-slider-heading;
//     }
//     p {
//       font-size: $rs-f-small-size-rem;
//       color: $rs-c-slider-text;
//     }
//   }
// }

#carousel-example-2 {
  position: relative;
  .carousel-item {
    z-index: 2;
    .view {
      // height: 550px;
      height: 525px;
      .rgba-black-strong {
        background: rgba(0,0,0,.6);
      }
    }
    .view1 {
      position: relative;
      overflow: hidden;
      cursor: default;
      .rgba-black-strong {
        background: rgba(0,0,0,.6);
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
    }
    .carousel-caption {
      position: absolute;
      bottom: 30%;
      text-align: left;
      max-width: 600px;
      h3 {
        font-size: $rs-f-xxlarge-size-rem;
        text-align: left;
        margin-bottom: 20px;
        color: $rs-c-slider-heading;
      }
      p {
        font-size: $rs-f-small-size-rem;
        color: $rs-c-slider-text;
      }
      .para-list {
        padding: 0 30px;
        margin: 0;
      }
      &.full-width {
        max-width: 940px;
      }
      .top-box {
        background: #9f105c;
        width:94%;
        padding: 12px;
        margin: 0 auto;
        position: relative;
        border-radius: 22px;
        span {
          // font-size: 1.25rem;
          font-weight: 600;
          font-family: avenir-light,sans-serif;
          text-align: center;
          display: block;
          a {
            color: #fff;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
              color: #fb3;
            }
          }
        } 
      }
      .funding-box {
        position: relative;
        padding: 10px 0;
        .card-image {
          .blue-txt {
            color: #E3F2FD;
            margin-bottom:15px;
          }
          .yellow-txt {
            color: #FFF59D;
            margin-bottom:15px;
          }
          &.blue-box {
            //background: linear-gradient(to top right,#0D47A1,#1E88E5)!important;
            background: #33b5e5!important;
            border-radius: 12px;
          }
          &.orange-box {
            //background: linear-gradient(to top right,#FF6F00,#FFA000)!important;
            background: #fb3;
            border-radius: 12px;
          }
        }
        .box-text {
          font-size: 1.75rem;
        }
        .card-title {
          font-size: 1.3rem;
        }
      }
    }
  }
  .carousel-control-prev, .carousel-control-next {
    z-index: 2;
  }
}