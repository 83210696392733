/** Global Stylesheet **/
@import "variables";
@import "basefile";

/** Layout Stylesheet **/
@import "header";
@import "menu-bar";
@import "mega-menu-bar";
@import "main.scss";
@import "snow-fall";
@import "sidebar";
@import "footer";
@import "screensize";

/** Nursery Pages Stylesheet **/
@import "home";
@import "about-us";
@import "director-welcome";
@import "facility";
@import "faculty-and-staff";
@import "leadership-team";
@import "location-hours";
@import "mission-statement";
@import "our-team";
@import "staff";
@import "our-feature";
@import "careers";
@import "learning-lifecycle";
@import "nursery-software";
@import "nursery-policies";
@import "covid-faqs";
@import "classrooms";
@import "baby-room";
@import "pre-school-room";
@import "toddler-room";
@import "admissions";
@import "baby-room-admission";
@import "pre-school-room-admission";
@import "toddler-room-admission";
@import "fee";
@import "baby-room-fee";
@import "pre-school-room-fee";
@import "toddler-room-fee";
@import "contact-us";
@import "funding";
@import "operations";
@import "eyfs";
@import "school-checklist";
@import "associated-members";
@import "reviews";
@import "food-hygiene";

/** Forms Stylesheet **/
@import "registration-from";
@import "emergency-contact-not-parents";
@import "other-individuals-legal-contact";
@import "session-recuired";
@import "onboard";
@import "all-about-me";
@import "common-form";
@import "continous-provision-planning";
@import "activity-form";
@import "focussed-activity";
@import "focussed-activity-form";
@import "nanny-service";
@import "nanny-registration-form";

/** Components Stylesheet **/
@import "address";
@import "child";
@import "parents";

.disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.no-gutter {
  padding: 0;
}

.ml-35px {
  margin-left: 35px;
}

.ml-35px {
  margin-left: 35px;
}

.btn.btn-link {
  color: #007bff !important;
  text-transform: capitalize;
}

.fw-bold {
  font-weight: bold;
}

.footer-section-stick {
  display: flex;
  width: 100%;
  word-break: break-all;
  font-size: 12px;
  box-sizing: border-box;
  border: 1px solid #dfdfdf;
  border-radius: 0 0 6px 6px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 -12px 24px 0 rgba(0, 0, 0, 0.04);
  align-items: center;
  padding: 10px;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  position: absolute;

  @media (max-width: 700px) {
    border: unset;
    box-shadow: unset;
    position: unset;
    justify-content: center;
    margin-top: 20px;
  }
}



.z-index-99 {
  z-index: 99;
}

.popover-content.popover-body {
  overflow: auto;
  max-height: 50vh;
}

.bootstrap-modal-center {
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.has-error {
  border: 1px solid #f31e1e !important;
}

.text-error {
  font-size: 12px;
  color: #f31e1e;
  line-height: 12px;
  margin: 0;
  padding: 0;
}

.spinner-border {
  height: 1rem;
  width: 1rem;
}

.bg-danger {
  background: #ff00002e !important;
}

.bg-warning {
  background: rgba(255, 255, 0, 0.185) !important;
}

.modal-full-width {
  margin: 0;
  max-width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-small {
  padding: 5px 15px !important;
}

.rns-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  text-decoration: none;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  border: 0.0625rem solid #dbdbdb;
  background-color: #fff;

  &:hover {
    border: 0.0625rem solid #3f729b;
  }

  .status-img{
    position: absolute;
    right: 25px;
    top:10px;
  }

  .tile-nav-link__img {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    &::before {
      font-size: 25px;
    }
  }
  .tile-nav-link-content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .tile-nav-link__text {
    color: #333;
    margin-bottom: 0;
    margin-top: 0;
    min-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.default-checkbox{
  position: unset !important;
  opacity: unset !important;
  pointer-events: unset !important;
}

label.form-check-label.disabled {
  opacity: 1;
}

.filter-box {
  margin-bottom: 30px;
  border: 1px solid gainsboro;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0 -4px 2px 0 #00000033, 0 2px 4px 0 #00000030;
  background: #3f729b;
  color: white;
  border-radius: 8px;
}
