.mega-menu {
    .carousel {
        .carousel-indicators {
            display: none;
        }
        .carousel-control-prev, .carousel-control-next {
            display: none;
        }
    }
    .list-unstyled {
        a {
            font-size: 0.875rem!important;
        }
    }
}
.login-dropdown {
    position: absolute!important;
    left: -110px!important;
    top: 45px!important;
    height: 58px;    
    a {
        color: #000;
        &.active {
            color: #fff!important;
        }
    }
}
