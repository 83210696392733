.page-footer {
    background-color: $rs-bg-footer;
    color: $rs-c-footer;
    a {
        color: $rs-c-footer;
    }
    .btn-floating {
        i {
            color: $rs-c-footer;
        }
    }
    hr {
        border-top: 1px solid #fff;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}