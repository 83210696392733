/** Registration Form Styling Goes Here **/
#registration-form {
  .section-wrapper  {
    padding: 25px 0;
    margin: 0;
  }
  .tab-content {
    box-shadow: none;
    padding: 20px 0;
    &.card {
      border: none;
    }
  }
  .child-registration {
    padding: 24px 40px;
    padding-top: 45px;
    position: relative;
    box-shadow: 0 3px 5px #dddedf;
    .form-header {
      margin-bottom: 15px;
    }
  }
  .parent-registration {
    padding: 24px 40px;
    padding-top: 45px;
    position: relative;
    box-shadow: 0 3px 5px #dddedf;
    .form-header {
      margin-bottom: 15px;
    }
  }
  .accordion {
    .card {
      .card-body {
        padding-top: 1.5rem;
      }
    }
    .card-header {
      padding: 0 20px;
      h5 {
        text-align: left;
        margin-bottom: 0;
        .mdb-accordion-indicator {
          top: 30px;
        }
      }
    }
  }
}

.mdb-auto-completer {
  position: absolute!important;
  z-index: 99999!important;
  width: 50%;
  left: 0;
  top: 0;
}




