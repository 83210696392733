#nanny-registration {
    position: relative;
    .stepper {
        min-height: 670px!important;
        .step-new-content {
            overflow: visible!important;
            .nanny-personal-info {
                // top: 45px;
                // margin-bottom: 80px;
                padding: 35px 40px;
                position: relative;
                box-shadow: 0 3px 5px #dddedf;
                .booking-wrapper {
                    .datepicker-inline {
                        top: -24px;
                    }
                }
                .btn-group {
                    text-align: left;
                    display: block;
                    .btn {
                        padding: 3px 25px;
                        position: relative;
                        left: 40px;
                        top: -5px;
                        color: #fff;
                    }
                }
                .form-header {
                    margin-bottom: 15px;
                }
                .md-form > input[type]:-webkit-autofill:not(.browser-default):not([type='search']) + label, .md-form > input[type='time']:not(.browser-default) + label {
                    width: auto;
                    transform: translateY(-24px);
                }
                .md-form {
                    .btn-info {
                        &.active {
                            transform: translateY(12px) scale(1);
                        }
                    }
                }
                .price-box-wrapper {
                    background: #33B5E5;
                    padding: 20px;
                    color: #fff;
                    overflow: hidden;
                    margin: 40px 0;
                    h5 {
                        display: inline-block;
                        margin-bottom: 0;
                        float: left;
                    }
                    span {
                        display: inline-block;
                        margin-bottom: 0;
                        float: right;
                    }
                }
                .btn-floating {
                    padding: 16px 18px;
                    mdb-icon {
                        width: auto;
                        i {
                            font-size: 26px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
    }
}