#school-checklist {
    .flex-column {
        .nav-item {
            a {
                margin-bottom: 5px;
            }
        }
        .active {
            a {
                background-color: #33b5e5;
                border-color: #33b5e5;
            }
        }
    }
    .list-group-item {
        padding: 0.7rem;
    }
    .md-tabs {
        border-radius: .25rem;
        .nav-item {
            height: 40px;
        }
    }
}