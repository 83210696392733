/** Nursery Policy Styling Goes Here **/
#covid-faqs {
    position: relative;
    padding: 40px 0;
    .tab-container {
      .tab-content  {
          box-shadow: none;
          padding-top: 0;
      }
    }
    .accordion {
      .card {
        .card-header {
          padding: 2rem 1.5rem;
        }
        .card-body {
          padding: 1rem;
          border: 1px solid #ccc;
        }
        .mdb-accordion-indicator {
          top: 39px;
        }
      }
      .faqs-list {
        padding: 22px 10px;
        max-height: 730px;
        overflow-y: auto;
        .question-wrap {
          line-height: 28px;
          margin: 0 0 15px 0;
          //box-shadow: 1px 2px 6px #ddd;
          h4 {
            color: #444;
            font-family: avenir-light,sans-serif;
            font-size: 1.25rem;
          }
          p {
            font-size: 1rem;
            padding: 10px 0;
            margin: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  