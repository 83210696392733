@-webkit-keyframes tm-animation-floating {from{-webkit-transform:translate(0,0px)}65%{-webkit-transform:translate(0,15px)}to{-webkit-transform:translate(0,0px)}}

@-moz-keyframes tm-animation-floating {from{-moz-transform:translate(0,0px)}65%{-moz-transform:translate(0,15px)}to{-moz-transform:translate(0,0px)}}

.tm-animation-slide-horizontal {-webkit-animation:tm-animation-slide-horizontal 3s linear infinite alternate-reverse both;animation:tm-animation-slide-horizontal 3s linear infinite alternate-reverse both}

#childcare-funding {
    padding: 30px 0;
    color: #273167;
    .section-heading {
        color: #273167;
        font-size: 1.875rem;
        line-height: 36px;
    }
    .about-funding {
        padding: 0;
    }
    .childcare-feature {
        position: relative;
        width: 100%;
        padding: 50px 15px 0 15px;
        .feature-inner {
            .featured-img {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                text-align: left;
                margin-bottom: 35px;
                img {
                    height: auto;
                    width: 100%;
                    vertical-align: top;
                    position: relative;
                    z-index: 10;
                    &.mobile-img {
                        width: 370px;
                        height: auto;
                        position: relative;
                        left: 80px;
                        top: 0;
                    }
                }
                .mr-img-left {
                    margin-left: 48px;
                }
                &.img-block {
                    left: -50px;
                    margin-top: -50px;
                    position: relative;
                    &:before {
                        left: 40px;
                        top: 50px;
                        border: 43px solid #fafafa;
                        border-radius: 50%;
                        content: '';
                        height: 318px;
                        position: absolute;
                        width: 318px;
                    }
                    &:after {
                        bottom: 50px;
                        right: -10px;
                        border: 43px solid #fafafa;
                        border-radius: 50%;
                        content: '';
                        height: 318px;
                        position: absolute;
                        width: 318px;
                    }
                }
                &.tm-animation-floating{
                    animation-name: tm-animation-floating;
                    animation-duration:3s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in-out;
                    -webkit-animation-name: tm-animation-floating;
                    -webkit-animation-duration:3s;
                    -webkit-animation-iteration-count:infinite;
                    -webkit-animation-timing-function:ease-in-out;
                    -moz-animation-name: Floating;
                    -moz-animation-duration:3s;
                    -moz-animation-iteration-count:infinite;
                    -moz-animation-timing-function:ease-in-out
                }
            }
        }
        .icon-wrapper {
            margin-bottom: 35px;
            img {
                margin-top: 40px;
                margin-bottom: 0;
            }
        }
        .text-wrapper {
            h2 {
                font-size: 2.5rem;
                text-align: left;
                margin-top: 12px;
                margin-bottom: 40px;
                color: #273167;
                font-weight: 400;
            }
            .line-1 {
                font-size: 1.125rem;
                color: #4F4F4F;
                line-height: 40px;
                margin-bottom: 35px;
            }
            .ordered-list {
                position: relative;
                margin-bottom: 50px;
                font-size: 1rem;
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    color: #777b92;
                    li {
                        list-style-type: none;
                        padding-left: 25px;
                        position: relative;
                        margin-bottom: 12px;
                        i {
                            // font-size: 80%;
                            font-size: 120%;
                            // line-height: 2;
                            line-height: 1.25;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 0;
                            color: #e94fca;
                        }
                    }
                }
            } 
            .accordion {
                .card {
                    .card-header {
                        background-color: #459dff;
                        a {
                            font-size: 1rem;
                            color: #fff;
                        }
                    }
                    .card-body {
                        p {
                            font-size: 1.125rem;
                            color: #4F4F4F;
                        }
                        ul {
                            font-size: 1rem;
                            color: #4F4F4F;
                            li {
                                margin-bottom: 5px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }    
        }
    }
    .stepper-height {
        height: 350px!important;
    }
    .stepper {
        .error-message {
            top: 160px;
            left: 30px;
        }
    }
}