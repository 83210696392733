#food-hygiene {
    position: relative;
    .card-wrapper {
        margin-bottom: 30px;
    }
    .card-body {
        .stars{
            color: #ffb800;
        }
        .rotate-btn {
            padding-top: 10px!important;
            color: #33b5e5;
            display:block;
        }
    }
    .back {
        .food-hygiene-content {
            height: 315px;
            clear: both;
            overflow: hidden;
        }
        .rotate-btn {
            padding-top: 10px!important;
            color: #33b5e5;
            display:block;
            i {
                margin-top: 0;
            }
        }
        .stars{
            color: #ffb800;
        }
    }
}

@media screen and (max-width: 1920px){
    #food-hygiene {
        .card-wrapper {
            height: 670px;
        }
        .front {
            .food-hygiene-wrap {
                height: 185px;
            }
            .food-img-wrap {
                width: 100%;
                height: auto;
                margin: 0 auto;
                align: center;
            }
        }
        .back {
            .food-hygiene-content {
                height: 450px;
            }
        }
    }

}

@media screen and (max-width: 1600px){
    #food-hygiene {
        .card-wrapper {
            height: 540px;
        }
        .front {
            .food-hygiene-wrap {
                height: 125px;
            }
        }
        .back {
            .food-hygiene-content {
                height: 330px;
            }
        }
    }
}

@media screen and (max-width: 768px){
    #food-hygiene {
        .front {
            .food-img-wrap {
                width: 380px;
            }
            .food-hygiene {
                img  {
                    margin: 0 auto;
                }
            }
            .food-hygiene-wrap {
                height: 95px;
            }
        }
    }
  }
